export const getFormattedFindings = (openFindings) => {
    try {
        if (openFindings) {
            (openFindings || []).forEach(item => {
                if (item && item.name === "INFO") {
                    item.name = "INFORMATIONAL";
                }
            });
        }
        return openFindings;
    } catch (e) {
        console.log("Error formatting open findings data")
    }
}