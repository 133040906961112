export const statusColor = {
    critical: ["var(--critical-severity)", "#EBD3D7"],
    high: ["var(--high-severity)", "#F5D7DC"],
    medium: ['var(--medium-severity)', '#FFE4DD'],
    low: ['var(--low-severity)', '#FFFAE0'],
    info: ['var(--info-severity)', "#F5F9FD"]
}
export const statusValue = {
    critical: 100,
    high: 75,
    medium: 50,
    low: 25,
    info: 10
}

export const severityColors = {
    critical: {
        name: "Critical",
        color: "var(--critical-severity)",
    },
    high: {
        name: "High",
        color: "var(--high-severity)",
    },
    medium: {
        name: "Medium",
        color: "var(--medium-severity)",
    },
    low: {
        name: "Low",
        color: "var(--low-severity)",
    },
    informational: {
        name: "Informational",
        color: "var(--info-severity)",
    }
}

