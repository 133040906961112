import React, {memo} from 'react';
import PropTypes from "prop-types";
import Paper from "../Dashboard/Paper";
import {Cell, Pie, PieChart, ResponsiveContainer} from "recharts";
import {areEqualProps, get2DigitFormattedNumber} from "../../actions/commonActions";
import Typography from "../../Typography";
import {statusColor} from "../../utils/constants";

const COLORS = ['var(--low-severity)', 'var(--high-severity)', 'var(--info-severity)', 'var(--medium-severity)', 'var(--critical-severity)'];


const CounterChartWidget = (props) => {
    const {
        data = [],
        title,
        colors = COLORS,
        className,
        labelPosition = "right",
        shadow = false,
        chartClassName = null
    } = props
    const total = data?.reduce((acc, obj) => acc + obj?.value, 0) || 0
    const chartData = filterChartData(data)
    return (
        <Paper shadow={shadow} className={`${className} px-5`}>
            <Typography variant={"subtitle1"} className={"text-headings"}>{title}</Typography>
            <div className="grid grid-cols-12">
                <div className={`${labelPosition === "right" ? "col-span-6" : "col-span-12"}`}>
                    <div className={`h-[110px] mt-4 ${chartClassName}`}>
                        <ResponsiveContainer>
                            <PieChart>
                                <circle cx={"50%"} cy={"50%"} r={50} fill={"var(--background)"}/>
                                <text
                                    x={'50%'}
                                    y={'50%'}
                                    textAnchor="middle"
                                    dominantBaseline="middle"
                                    className={"text-[32px] font-semibold fill-body"}
                                >
                                    {get2DigitFormattedNumber(total)}
                                </text>
                                <Pie
                                    isAnimationActive={true}
                                    startAngle={90}
                                    endAngle={-270}
                                    data={chartData}
                                    innerRadius={40}
                                    outerRadius={50}
                                    fill="#000"
                                    paddingAngle={5}
                                    cornerRadius={5}
                                    dataKey="value"
                                >
                                    {chartData.map((entry, index) => {
                                         const itemType = getItemType(entry);
                                         return (
                                            <Cell key={`cell-${index}`} fill={colors[itemType]?.color}/>
                                         )
                                    })}

                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                </div>
                <div className={`${labelPosition === "right" ? "col-span-6" : "col-span-12"}`}>
                    <div className={"flex flex-wrap items-center h-full gap-x-4"}>
                        {
                            data?.map((item, index) => {
                                const itemType = getItemType(item);
                                return (
                                    <div className={`${labelPosition === "right" ? "flex-[100%]" : ""}`} key={index}>
                                        <div className="inline-block w-6 h-1 rounded-[10px] align-middle mr-2.5"
                                             style={{background: colors[itemType]?.color}}></div>
                                        <Typography variant={"caption"}
                                                    className={`inline-block capitalize ${labelPosition === "bottom" && "!text-[9px]"} `}>{item.value} {item.name}</Typography>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </Paper>
    );
};

CounterChartWidget.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    chartClassName: PropTypes.string,
    data: PropTypes.array,
    colors: PropTypes.array,
    labelPosition: PropTypes.oneOf(["right", "bottom"])
}

export default memo(CounterChartWidget, areEqualProps);

export const filterChartData = (data) => {
    try {
        return data?.filter(item => item.value !== 0);
    } catch (e) {
        console.log("Error parsing chartdata")
    }
}


export const getItemType = (item) => {
    try {
        const itemName = item?.name;
        let itemType = "";
        if (itemName) {
            itemType = itemName.replace(" ", "_").toLowerCase();
        }
        return itemType;
    } catch (e) {
        console.log("Inavlid Item Type");
    }
}